<template>
  <div class="main-account-manager">
    <b-card title="Quản lý công văn">
      <b-row
        lg="12"
        md="12"
        xs="12"
      >
        <b-col
          lg="3"
          md="3"
          xs="12"
        >
          <b-form-group>
            <label class="font-size-label-custom font-weight-bold">Tìm kiếm theo từ khóa</label>

            <b-form-input
              v-model="documentSchoolSearchDTO.keyWord"
              type="text"
              placeholder="Nhập để tìm kiếm"
              @keyup.enter="getListAllDocument"
            />
          </b-form-group>

        </b-col>
        <b-col
          lg="3"
          md="3"
          xs="12"
        >
          <b-form-group>
            <label class="font-size-label-custom font-weight-bold">Loại công văn</label>
            <v-select
              v-model="documentSchoolSearchDTO.documentType"
              :options="listDocumentType"
              label="label"
              :reduce="(idAsset) => idAsset.value"
            >
              <span
                slot="no-options"
                @click="$refs.select.open = false"
              >
                Không có dữ liệu
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          lg="3"
          md="3"
          xs="12"
        >
          <div class="search-date">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label class="font-weight-bold font-size-label-custom">Từ ngày</label>
                  <flat-pickr
                    v-model="documentSchoolSearchDTO.fromDate"
                    class="form-control"
                    :config="configFlatpickr"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group>
                  <label class="font-weight-bold font-size-label-custom">Đến ngày</label>
                  <flat-pickr
                    v-model="documentSchoolSearchDTO.toDate"
                    class="form-control"
                    :config="configFlatpickr"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col
          class="mt-2"
          lg="3"
          md="3"
          xs="12"
        >
          <b-form-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="getListAllDocument"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              <span class="align-middle">Tìm kiếm</span>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <div class="table-school-block">
            <b-row>
              <b-col
                lg="12"
                style="margin: auto"
              >
                <div class="acction-add-account mb-2">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="getFormAddNewDocument"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Thêm mới</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <span style="color: green">Có tổng số {{ totalCountList }} bản ghi</span>
            <b-table
              responsive
              :items="listDocument"
              :per-page="perrPage"
              :current-page="currentPage"
              :fields="fields"
            >
              <template #cell(index)="data">
                <span>{{ data.index + 1 }}</span>
              </template>
              <template #cell(status)="data">
                <b-badge
                  v-if="data.value === 0"
                  variant="danger"
                >
                  Không hoạt động
                </b-badge>
                <b-badge
                  v-if="data.value === 1"
                  variant="success"
                >
                  Hoạt động
                </b-badge>
              </template>
              <template #cell(hanhdong)="data">
                <b-button
                  v-b-tooltip.hover.top="'Cập nhật'"
                  variant="flat-primary"
                  class="btn-icon"
                  @click="updateDocument(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="'Xóa'"
                  variant="flat-danger"
                  class="btn-icon"
                  @click="deleteDocument(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
            <template>
              <b-pagination
                v-model="currentPage"
                class="float-right"
                :per-page="perrPage"
                hide-goto-end-buttons
                :total-rows="rows"
              />
            </template>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BFormInput, BButton, BTable, BPagination, BBadge, BFormGroup,
} from 'bootstrap-vue'

import { required, max } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import vSelect from 'vue-select'


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BBadge,
    vSelect,
    flatPickr,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      configFlatpickr: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
      },
      documentSchoolSearchDTO: {
        pageIndex: null,
        pageSize: null,
        keyWord: null,
        status: null,
      },
      listDocument: [
      ],
      currentPage: 1,
      perrPage: 10,
      required,
      max,
      totalRecordsList: 0,
      totalCountList: null,
      fields: [
        {
          key: 'index', label: 'Id', thClass: 'custom-th-index',
        },
        {
          key: 'documentCode', label: 'Số, ký hiệu văn bản',
        },
        {
          key: 'name', label: 'Tên loại và trích yếu nội dung văn bản',
        },
        {
          key: 'signer', label: 'Người ký',
        },
        {
          key: 'recipient', label: 'Nơi nhận văn bản',
        },
        {
          key: 'note', label: 'Ghi chú',
        },
        {
          key: 'hanhdong', label: 'Hành động', thClass: 'custom-th-action-account', tdClass: 'custom-td-action-account',
        },
      ],
      listPerPage: [
        3, 5, 10,
      ],
      listDocumentType: [
        { value: 0, label: 'Khác' },
        { value: 1, label: 'Công văn đi' },
        { value: 2, label: 'Công văn đến' },
      ],
    }
  },
  computed: {
    rows() {
      if (this.listDocument) {
        return this.listDocument.length
      }
    },
  },
  created() {
    this.getListAllDocument()
  },
  methods: {
    getListAllDocument() {
      this.$crm.post('document-school/find-by-condition', this.documentSchoolSearchDTO).then(response => {
        if (response.data.listData) {
          this.listDocument = response.data.listData
          this.totalCountList = this.listDocument.length
        }
      })
    },
    getFormAddNewDocument() {
      this.$router.push('/add-document')
    },
    updateDocument(item) {
      this.$router.push({
        name: 'update-document',
        query: {
          id: item.id,
        },
      })
    },
    deleteDocument(item) {
      this.$swal({
        title: 'Xóa công văn',
        text: `Bạn có muốn xóa công văn ${item.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.get(`document-school/delete/${item.id}`).then(res => {
            this.getListAllDocument()
            this.$swal({
              icon: 'success',
              title: 'Xóa công văn!',
              text: 'Xóa công văn thành công',
              showConfirmButton: false,
              timer: 1500,
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/custom/account';
@import "~ag-grid-community/dist/styles/ag-grid.scss";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
